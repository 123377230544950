<template>
  <!-- navigation START -->
  <div
    id="nav"
    :class="[
      { 'show-nav' : visibleNav && $route.name !== 'index' },
      { 'only-arrow' : pageTitle === ' ' }
    ]"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <!-- navigation:simple(screens) START -->
      <simple-navigation
        v-if="pageTitle"
        :pageTitle="pageTitle"
      />
      <!-- navigation:simple(screen) END -->

      <!-- navigation:default(screens) START -->
      <default-navigation v-else />
      <!-- navigation:default(screens) END -->
    </transition>
  </div>
  <!-- navigation END -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'app-navigation',
  components: {
    DefaultNavigation: () => import('./navigation/Default.vue'),
    SimpleNavigation: () => import('./navigation/Simple.vue'),
  },
  data() {
    return {
      visibleNav: false,
    };
  },
  computed: {
    ...mapGetters({
      pageTitle: 'pageTitle',
    }),
  },
  mounted() {
    // check for active theme
    const htmlElement = document.documentElement;
    const theme = localStorage.getItem('theme');

    if (theme === 'dark') {
      localStorage.setItem('theme', 'dark');
      htmlElement.setAttribute('theme', 'dark');
    } else {
      localStorage.setItem('theme', 'light');
      htmlElement.setAttribute('theme', 'light');
    }

    // set height of the viewport
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // We listen to the resize event
    window.addEventListener('resize', () => {
      const vhResize = vh;
      document.documentElement.style.setProperty('--vh', `${vhResize}px`);
    });

    // show footer after component mounted
    this.$nextTick(() => {
      if (this.$route.name !== 'index'
        && this.$route.name !== 'logout'
        && this.$route.name !== null) {
        this.visibleNav = true;
      } else {
        this.visibleNav = false;
      }
    });
  },
  watch: {
    // show footer after component mounted
    $route(to) {
      this.$nextTick(() => {
        if (to.name !== 'index'
          && to.name !== 'logout'
          && to.name !== null) {
          setTimeout(() => {
            this.visibleNav = true;
          }, 350);
        }
      });
    },
  },
};
</script>
